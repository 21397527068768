import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withTrans } from '../../i18n/withTrans'
import LocalizedLink from '../LocalizedLink'
import './social-store.scss'
import img_article from '../../images/hairpedia-article.png'
import vid_hairpedia from '../../images/hairpedia-video.png'

class HomepageSocialStore extends Component {
    render() {
        const { t } = this.props
        return (
            <div className="feature-page">
                <div className="feature-page-wrapper">
                    <Container>
                        <Row className="feature-menu-row">
                            <Col sm={12} md={6} className="p-0 pr-md-1">
                                <div className="feature-menu feature-menu__article slide-in-left">
                                    <img
                                        src={img_article}
                                        alt="Ellips Store Location"
                                        className="img-fluid radius-top-left"
                                    />
                                    <div className="menu-title">
                                        <h2>
                                            Our
                                            <br />
                                            Store
                                        </h2>
                                    </div>
                                    <div className="menu-desc">
                                        <p>Discover Ellips Store Worldwide</p>
                                    </div>
                                    <LocalizedLink
                                        to="/store-location"
                                        className="btn btn-outline-primary btn-outline-primary__outline-black see-detail grow shrink mont-regular"
                                        target="_self"
                                    >
                                        See All Store
                                    </LocalizedLink>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className="p-0 pl-md-1">
                                <div className="feature-menu feature-menu__video slide-in-right">
                                    <img
                                        src={vid_hairpedia}
                                        alt="Ellips Social Media"
                                        className="img-fluid radius-bottom-right"
                                    />
                                    <div className="menu-title">
                                        <h2>
                                            Our
                                            <br />
                                            Social Media
                                        </h2>
                                    </div>
                                    <div className="menu-desc">
                                        <p>
                                            Discover Ellips Social Media
                                            Worldwide
                                        </p>
                                    </div>
                                    <LocalizedLink
                                        to="/social-media"
                                        className="btn btn-outline-primary btn-outline-primary__outline-black see-detail grow shrink mont-regular"
                                        target="_self"
                                    >
                                        See All Social Media
                                    </LocalizedLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
export default withTrans(HomepageSocialStore)
