import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import LocalizedLink from '../LocalizedLink'
import { Fade, Zoom } from 'react-reveal'
import { withTrans } from '../../i18n/withTrans'

import './category-product.scss'

class HomepageCategoryProduct extends Component {
    render() {
        const { t } = this.props
        const data = this.props.data
        // console.log('Data Product Category = ', data)

        const itemsSwiper = data.map((item) =>(
            <SwiperSlide className="product-category-container">
                <Zoom>
                    <div className="product-category-content">
                        <LocalizedLink to={`/${item.slug}`}>
                            <div className="product-image">
                                <img
                                    src={item.image}
                                    alt={`Ellips - ${t(
                                        `category.${item.slug}`
                                    )}`}
                                    className="img-fluid w-100 d-block"
                                />
                            </div>
                            <div className="product-text">
                                <div className="product-title mont-bold">
                                    <h2>{t(`category.${item.slug}`)}</h2>
                                </div>
                                <div className="product-desc mont-regular">
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </LocalizedLink>
                        <LocalizedLink
                            to={`/${item.slug}`}
                            className="btn btn-outline-primary btn-outline-primary__outline-black see-detail grow shrink mont-regular"
                        >
                            {t('common.button')}
                        </LocalizedLink>
                    </div>
                </Zoom>
            </SwiperSlide>
        ))

        return (
            <Container>
                <Row>
                    <Col sm={12} className="text-center mb-2 mb-md-4">
                        <Fade down>
                            <div className="section-title mont-medium">
                                <h3>
                                    <span>{t('home.category.title1')}</span>{' '}
                                    {t('home.category.title2')}
                                </h3>
                            </div>
                        </Fade>
                        <Fade up>
                            <div className="section-desc mont-regular">
                                <p>{t('home.category.subtitle')}</p>
                            </div>
                        </Fade>
                    </Col>
                </Row>
                <Row>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={20}
                        autoHeight={false}
                        loop={false}
                        navigation={{
                            nextEl: '.arrow-right',
                            prevEl: '.arrow-left',
                        }}
                        pagination={false}
                        autoplay={false}
                        // autoplay={{
                        //     delay: 10000,
                        //     disableOnInteraction: true,
                        // }}
                        breakpoints={{
                            280: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        {itemsSwiper}
                    </Swiper>
                    <div className="box-navigation">
                        <a
                            className="arrow-left arrow"
                            href="#categoryCarousel"
                            role="button"
                        >
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                            ></FontAwesomeIcon>
                        </a>
                        <a
                            className="arrow-right arrow"
                            href="#categoryCarousel"
                            role="button"
                        >
                            <FontAwesomeIcon
                                icon={faAngleRight}
                            ></FontAwesomeIcon>
                        </a>
                    </div>
                </Row>
            </Container>
        )
    }
}

export default withTrans(HomepageCategoryProduct)
